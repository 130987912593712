<template>
  <div class="contaier">
    <!-- 头部 -->
      <div class="title">
        <div class="aa"> 
          <div><img style="width:130px;height:130px;border-radius:50%" :src="headimg.logo_img" /></div>
          <div>
            <div>{{headimg.title}}</div>
            <div style="font-size:24px;">-- {{headimg.title_ls}} --</div>
          </div>
        </div>
        <div class="bb">
          <div>
            <div>小程序二维码</div>
            <div><img :src="headimg.xcx_img" alt=""></div>
          </div>
          <div>
            <div>企业二维码</div>
            <div><img :src="headimg.gzh_img" alt=""></div>
          </div>
        </div>
        
      </div>

      <div class="infotitle">查看商品详情</div> 
      <div class="contents">
          <div><img :src="info.fm_img" ></div>
          <div>
              <div>标的物类型&emsp;:&emsp;{{info.cate_name}}</div>
              <div>标的物名称&emsp;:&emsp;{{info.sp_name}}</div>
              <div>标的物地址&emsp;:&emsp;{{info.area}}</div>
              <div>出售人姓名&emsp;:&emsp;{{info.name}}</div>
              <div>电&emsp;&emsp;&emsp;话&emsp;:&emsp;{{info.tpl}}</div>
              <div>原&emsp;&emsp;&emsp;价&emsp;:&emsp;{{info.shue}} 万</div>
              <div>预&#8197;售&#8197;&#8197;价&#8197;格&emsp;:&emsp;{{info.shue_ys}} 万</div>
              <div>判&#8197;决&#8197;&#8197;卷&#8197;号&emsp;:&emsp;{{info.panjue}}</div>
              <div>执&#8197;行&#8197;&#8197;卷&#8197;号&emsp;:&emsp;{{info.zhixing}}</div>
              <div>执&#8197;行&#8197;&#8197;程&#8197;度&emsp;:&emsp;{{info.recommens_name}}</div>
              <div>法&#8197;官&#8197;&#8197;电&#8197;话&emsp;:&emsp;{{info.fg_tpl}}</div>
              <div>法&#8197;官&#8197;&#8197;姓&#8197;名&emsp;:&emsp;{{info.fg_name}}</div>
              <div>备&#8197;注&#8197;&#8197;信&#8197;息&emsp;:&emsp;{{info.content}}</div>
          </div>
      </div>
      <div class="middles">商品详情图</div>
      <div class="infoImgs">
            <div v-for="(item,index) in infoImg" :key="index">
                <img :src="item" alt="">
            </div>
      </div>
          <!-- 公司简介标题 -->
    <div class="address">
        <div>公司详情</div> 
        <div></div>
      </div>
    <!-- 公司简介信息 -->
    <div class="introduce">
      <div>公司地址 : {{endContent.end_address}}</div>
      <div>公司官网 : {{endContent.end_contact}}</div>
      <div>加入我们 : {{endContent.end_join}}</div>
      <div>联系我们 : {{endContent.end_number}}</div>
      <div>网站备案号 : {{endContent.web_icp}}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import requests from "../../api/request";
export default {
      data(){
        return{
            id:'',
            info:[],
            infoImg:[],
            headimg:[],
            endContent:[]
        }
      },
      mounted(){
        this.id = this.$route.query.id;
        this.goodsInfo();
        this.headImg();
        this.end();
      },
      methods:{
        // 顶部图片
      async  headImg(){
          let res = await requests.post('/web/get_topinfo')
          this.headimg = res.data
          // console.log(this.headimg)
        },
        // 底部内容
        async  end(){
          let res = await requests.post('/web/get_endinfo')
          this.endContent = res.data
          // console.log(this.endContent)
        },
        goodsInfo(){
          axios.post('/api/web/get_goods_Details',{
            data:{
              id:this.id
            }
          }).then(res=>{
            console.log(res)
            this.info = res.data.data
            this.infoImg = res.data.data.img
          }).catch(err=>{
            console.log(err)
          })
        }
      }
}
</script>

<style >
    .contaier{
        min-width: 1300px;
        /* height: 1700px; */
        background: linear-gradient(#FF0000, #FFF); 
    }
    .contents{
      display: flex;
      width: 90%;
      justify-content: space-around;
      font-size: 30px;
    }
    .middles ,.infotitle{
       width: 100%;
       height: 100px;
       text-align: center;
       font-size: 40px;
       font-weight: bold;
       /* color: #FF1414; */
    }
    .infoImgs{
     text-align: center;
    }
    .infoImgs img{
      width: 600px;
      height: 600px;
    }
    .contents img{
      width: 600px;
      height: 600px;
    }
    .title{
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
    .title .aa{
      font-size: 34px;
      font-weight: bolder;
      color: #FFF;
      width: 55%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .title .bb{
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 17px;
      font-weight: bolder;
    }
      .title img{
        width: 100px;
        height: 100px;
      }
      .message{
        margin-top: 20px;
        width: 100%;
        text-align: center;
        font-size: 40px;
        font-weight: bolder;
        color: #FF1414;
      }
            .address{
        margin: 0 auto;
        width: 85%;
        /* height: 100px; */
        /* background-color: #fff;
        border-radius: 10px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 30px;
        font-weight: bold;
        color: #FF1414;
      }
      .address div{
        padding: 20px 30px;
      }
      .introduce{
        width: 85%;
        font-size: 22px;
        font-weight: bolder;
        margin: 0 auto;
        padding-bottom: 20px;
      }
      .introduce div{
        margin-top: 20px;
        padding: 0 30px;
      }
    
</style>