<template>
<div>
    <div class="best">
      <div @click="preview" class="aa">
        <img src="../../assets/icon/left.png" alt="">
        <div>上一条</div>
      </div>

      <!-- 富文本 -->
      <div class="sec"><section v-html="newsInfo.content"></section></div>

      <div @click="next" class="aa">
        <img src="../../assets/icon/right.png" alt="">
        <div>下一条</div>
      </div>
  </div>
</div>
  
  
</template>

<script>
import axios from 'axios';
import requests from "../../api/request";
export default {
      data(){
        return{
            id:'',
            newsInfo:[]
        }
      },
      mounted(){
        this.id = this.$route.query.id,
        this.info()
      },
      methods:{
        // async info(){
        //   let res = await requests.post('/web/get_news_Details?id='+this.id)
        //   console.log(res)
        // }
        info(){
          axios.post('/api/web/get_news_Details',{
            data:{
              id:this.id
            }
          }).then(res=>{
            console.log(res)
            this.newsInfo = res.data.data
          }).catch(err=>{
            console.log(err)
          })
        },
        next(){
          axios.post('/api/web/get_Next',{
            data:{
              id:this.id
            }
          }).then(res=>{
            console.log(res)
            this.id = res.data.data.id
            this.newsInfo = res.data.data
          }).catch(err=>{
            console.log(err)
          })
        },
        preview(){
          axios.post('/api/web/get_Pre',{
            data:{
              id:this.id
            }
          }).then(res=>{
            console.log(res)
            this.id = res.data.data.id
            this.newsInfo = res.data.data
          }).catch(err=>{
            console.log(err)
          })
        }
      }
}
</script>

<style scoped>
  .best{
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 70%; */
  }
  img{
    width: 80px;
    height: 80px;
  }
  .aa{
    text-align: center;
    font-weight: bolder;
    font-size: 24px;
  }
  .sec{
      width: 60%;
  }

</style>