<template>
  <div>
    <!-- woshigenzujian -->
    <router-view v-wechat-title='$route.meta.title'></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
