<template>
    <div class="big">
        <div class="goodsList">
            <div class="left" v-for="(item,index) in goods" :key="index" @click="goodsInfo(item.id)">
                          <div :class="item.tuijian==1?'love':''">{{item.tuijian==1?'平台推荐':''}}</div>
                          <div><img :src="item.fm_img"></div>
                          <div>标的物类型 : {{item.cate_name}}</div>
                          <div>债 权 名  称 : {{item.sp_name}}</div>
                          <div >标的物原价 : {{item.shue}} 万</div>
                          <div style="font-weight:bold; color:#FF1414">标的物现价 : {{item.shue_ys}} 万 ({{item.beizhu}}折)</div>
                          <div>执 行 状  态 : {{item.recommens_name}}</div>
                          <div style="font-weight:bold; color:#FF1414">浏&emsp;览&emsp;量 : {{item.view}} 次</div>
            </div>
        </div>
        <div><van-pagination v-model="currentPage" :total-items="total" :items-per-page="lastPage" @change="pageChange"/></div>
    </div>
    
</template>

<script>
import requests from "../../api/request";
export default {
    data(){
        return{
            goods:[],
            // 当前页
            currentPage:'',
            // 总也是
            total:'',
            // 最后一页
            lastPage:'',
        }
    },
    mounted(){
        this.moreGoods();
    },
    methods:{
        async  moreGoods(){
            let res = await requests.post('/web/get_goods_Listmore')
            console.log(res)
            this.currentPage = res.data.current_page
            this.total = res.data.total
            this.lastPage = res.data.per_page
            this.goods = res.data.data
            // console.log(this.endContent)
      },
      // 商品详情
      goodsInfo(e){
        clearInterval(this.timer)
        this.timer = null
        clearInterval(this.timer1)
        this.timer1 = null
        this.$router.push({
          path:'/link',
          query:{
            id:e
          }
        })
      },
    async  pageChange(e){
        let res = await requests.post('/web/get_goods_Listmore?page='+e)
        console.log(res)
            this.goods = res.data.data
      }
    }
}
</script>

<style scoped>
    .big{
        min-width: 1200px;
        height: 1700px;
        background: linear-gradient(#FF0000, #FFF); 
    }
    .goodsList{
        padding: 30px 30px;
        display: flex;
        flex-wrap: wrap;
    }
    .goodsList .left{
        margin: 20px 0 0 80px;
    }
    img{
        width: 300px;
        height: 300px;
    }
    .love{
         width: 60px;
         text-align: center;
         height: 20px;
         line-height: 20px;
         border-radius: 10px;
         background-color: #FF1414;
         color: #FFF;
         font-weight: bolder;
         font-size: 12px;
         position: absolute;
         margin-top: 5px;
         margin-left: 5px;
      }
</style>