<template>
  <div class="contaier">
    <div class="box">
      <!-- 头部 -->
      <div class="titles">
        <div class="aa"> 
          <div><img style="width:130px;height:130px;border-radius:50%" :src="headimg.logo_img" /></div>
          <div>
            <div>{{headimg.title}}</div>
            <div style="font-size:24px;">-- {{headimg.title_ls}} --</div>
          </div>
        </div>
        <div class="bb">
          <div>
            <div>小程序二维码</div>
            <div><img :src="headimg.xcx_img" alt=""></div>
          </div>
          <div>
            <div>企业二维码</div>
            <div><img :src="headimg.gzh_img" alt=""></div>
          </div>
        </div>
        
      </div>
      <!-- 选项卡 -->
      <div class="nav">
        <div @click="sel(1)" :class="select==1?'yes':'no'">首页</div>
        <div @click="sel(3)" :class="select==3?'yes':'no'">公司介绍</div>
        <div @click="sel(5)" :class="select==5?'yes':'no'">求购信息</div>
        <div @click="sel(2)" :class="select==2?'yes':'no'">联系我们</div>
        <div @click="sel(4)" :class="select==4?'yes':'no'">债权交易流程介绍</div>
      </div>
      <!-- 选项卡内容 -->
      <div  class="content">
        <div v-if="select==1">
          <div class="links">相关链接</div>
          <!-- 首页内容 -->
          <div class="linkbox">
            <div @click="goto(item.src)"  v-for="(item,index) in link" :key="index">
                <div><img :src="item.img" alt=""></div>
                <div>{{item.title}}</div>
            </div>
          </div>
          <!-- 首页内容-列表 -->
          <div class="proBox">
              <div class="project">
                  <div class="call">
                    <div class="scroll">
                        <div>债权列表</div>
                        <div @click="more" style="font-size:18px;">查看更多>></div> 
                    </div>
                    <!-- <div class="xia"> -->
                    <div class="left" v-for="(item,index) in goods" :key="index" @click="goodsInfo(item.id)">
                        <div :class="item.tuijian==1?'love':''">{{item.tuijian==1?'平台推荐':''}}</div>
                        <div><img :src="item.fm_img"></div>
                        <div>标的物类型 : {{item.cate_name}}</div>
                        <div>债 权 名  称 : {{item.sp_name}}</div>
                        <div >标的物原价 : {{item.shue}} 万</div>
                        <div style="font-weight:bold; color:#FF1414">标的物现价 : {{item.shue_ys}} 万 ({{item.beizhu}}折)</div>
                        <div>执 行 状  态 : {{item.recommens_name}}</div>
                        <div style="font-weight:bold; color:#FF1414">浏&emsp;览&emsp;量 : {{item.view}} 次</div>
                    </div>
                    <!-- </div> -->
                  </div>
                  <!-- 滚动条 -->
                  <div class="notice">
                      <div class="new" style="margin-top:-45px;">新闻列表</div>
                      <div class="aa">
                        <div  v-for="(item,index) in notice" :key="index" @click="info(item.id)">
                          <van-notice-bar left-icon="volume-o" scrollable :text="item.title" mode="link"/>
                        </div>
                      </div>
                  </div>  
              </div>
          </div>
           
        </div>
        <!-- 首页-联系我们内容 -->
         <div v-if="select==2">
              <div class="comm">意见反馈</div>
              <div class="area">
                <div class="bigInp">反馈 : <input v-model="content" placeholder="请输入您的反馈意见"/></div>
                <div>电话 : <input  maxlength="11" placeholder="请输入电话号" v-model="phone">  </div>
                <div>姓名 : <input type="text" placeholder="请输入姓名" v-model="username"></div>
              </div>
              
              <div @click="submit" class="btn">提交</div>
         </div> 
        <!-- 公司介绍 -->
        <div v-if="select==3">
            <!-- 公司简介标题 -->
            <!-- <div class="message">公司介绍</div> -->
            <div style="margin-left:20px;"> <section v-html="jieshao.content"></section></div>
        </div>

        <!-- 流程介绍 -->
        <div v-if="select==4">
            <div class="message">债权交易流程介绍</div>
            <div style="padding: 0 30px;"> <section v-html="rlues"></section></div>
        </div>

        <!-- 求购内容 -->
        <div v-if="select==5" class="buyBox">
          <div class="buy" v-for="(item,index) in buyGoods" :key="index" style="margin-top:30px;">
                <div>意向标的物&emsp;:&emsp;{{item.cate_name}}</div>
                <div>意向物金额&emsp;:&emsp;{{item.jine}}(万)</div>
                <div>所&emsp;在&emsp;地&emsp;:&emsp;{{item.qg_area}}</div>
                <div>求购者姓名&emsp;:&emsp;{{item.name}}</div>
                <div>求购者电话&emsp;:&emsp;{{item.tpl}}</div>
          </div>
          <div style="margin-top:10px;"><van-pagination v-model="currentPage" :total-items="total" :items-per-page="perPage" @change="pageChange" /></div>
        </div>
      </div>
      
    </div>
    <!-- 免责声明 -->
    <div class="duty" @click="dutyInfo">
        <div>免责声明</div>
        <div><img src="../../assets/icon/right.png" alt=""></div>
    </div>
    <!-- 公司简介标题 -->
    <div class="address">
        <div>公司详情</div> 
        <div></div>
      </div>
    <!-- 公司简介信息 -->
    <div class="introduce">
      <div>公司地址 : {{endContent.end_address}}</div>
      <div>公司官网 : {{endContent.end_contact}}</div>
      <div>加入我们 : {{endContent.end_join}}</div>
      <div>联系我们 : {{endContent.end_number}}</div>
      <div>网站备案号 : {{endContent.web_icp}}</div>
    </div>
    <!-- 免责声明弹出框 -->
    <van-dialog v-model="dutyshow"  show-cancel-button width='700'>
        <div class="dutypopup"><section v-html="dutymsg"></section></div>
    </van-dialog>
    <!-- 表单提交弹出框 -->
    <van-dialog v-model="show"  show-cancel-button>
        <div class="popup">{{msg}}</div>
    </van-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import requests from "../../api/request";
export default {
    data(){
      return{
          link:[
            
              {id:1,src:"https://www.court.gov.cn",img:"http://www.zgydzqjy.com/upload/icon/20220420131510.jpg",title:"人民法院"},
              {id:2,src:"http://zxgk.court.gov.cn",img:"http://www.zgydzqjy.com/upload/icon/20220420131457.jpg",title:"执行中心"},
              {id:3,src:"http://www.pbccrc.org.cn",img:"http://www.zgydzqjy.com/upload/icon/20220420131439.jpg",title:"征信中心"},
              {id:4,src:"https://wenshu.court.gov.cn",img:"http://www.zgydzqjy.com/upload/icon/20220420131518.jpg",title:"裁判文书"},
            ],
          phone:'',
          username:'',  
          notice:[],
          headimg:[],
          select:1,
          content:'',
          endContent:'',
          jieshao:[],
          // 当前页
          currentPage:'',
          // 总也是
          total:'',
          // 最后一页
          perPage:'',
          page:1,
          //新闻最后页码
          allPage:'',
          // 商品最后页码
          allPages:'',
          buyGoods:[],
          // 新闻定时器
          timer:null,
          // 商品定时器
          timer1:null,
          pages:1,
          goods:[],
          // 表单提交弹窗
          show:false,
          // 免责声明弹窗
          dutyshow:false,
          // 免责声明富文本
          dutymsg:[],
          // 流程富文本
          rlues:[],
          msg:'',

      }
    },
    beforeDestroy(){
      clearInterval(this.timer)
      this.timer = null
      clearInterval(this.timer1)
      this.timer1 = null
    },
    mounted(){
        this.headImg();
        // 定时器走完之后回归原样
        setInterval(()=>{
          this.page = 1;
          this.news();
        },16000)
        // 再走定时器
        setInterval(()=>{
          this.setTime();
        },18000)

        // 商品定时器走完之后回归原样
        setInterval(()=>{
          this.pages = 1;
          this.goodlist();
        },51000)
        // 再走定时器
        setInterval(()=>{
          this.setTime1();
        },55000)
        this.end();
        this.jieShao();
        this.news();
        this.setTime();
        this.setTime1();
        this.goodlist();
    },
    methods:{
      // 顶部图片
     async  headImg(){
        let res = await requests.post('/web/get_topinfo')
        this.headimg = res.data
        // console.log(this.headimg)
      },
      // 底部内容
      async  end(){
        let res = await requests.post('/web/get_endinfo')
        this.endContent = res.data
        console.log(this.endContent)
      },
      // 公司介绍
       async jieShao(){
        let res = await requests.post('/web/get_introductioninfo')
        this.jieshao = res.data
        // console.log(this.jieshao)
      },
      // 新闻标题
       async news(){
        let res = await requests.post('/web/get_newsinfo?page='+this.page)
        this.notice = res.data.data
        this.allPage = res.data.last_page
        // console.log(this.notice)
      },
      // https://www.zgydzqjy.com/api/web/get_goods_List 
      // https://www.zgydzqjy.com/api/web/get_goods_Details
      // 债券滚动
      async goodlist(){
        let res = await requests.post('/web/get_goods_List?page='+this.pages)
        // console.log(res)
        this.goods = res.data.data
        this.allPages = res.data.last_page
      },
      // 新闻定时器
      setTime(){
        if(this.timer==null){
          this.timer = setInterval(()=>{
              this.page++
              this.news();
              if(this.page==this.allPage){
                clearInterval(this.timer)
                this.timer = null
              }
          },6000)
          
        }
      },
      // 商品定时器
      setTime1(){
        if(this.timer1==null){
          this.timer1 = setInterval(()=>{
              this.pages++
              this.goodlist();
              if(this.pages==this.allPages){
                clearInterval(this.timer1)
                this.timer1 = null
              }
          },6000)
          
        }
      },
      // 商品详情
      goodsInfo(e){
        clearInterval(this.timer)
        this.timer = null
        clearInterval(this.timer1)
        this.timer1 = null
        this.$router.push({
          path:'/link',
          query:{
            id:e
          }
        })
      },
      // 新闻详情
      info(e){
        clearInterval(this.timer)
        this.timer = null
        clearInterval(this.timer1)
        this.timer1 = null
        this.$router.push({
          path:'/goods',
          query:{
            id:e
          }
        })
      },
      // 更多商品
      more(){
        clearInterval(this.timer)
        this.timer = null
        clearInterval(this.timer1)
        this.timer1 = null
        this.$router.push({
          path:'/more',
        })
      },
      // 跳外部链接
      goto(e){
        window.location.href = e
      },
      // 选项卡各内容
      async sel(e){
        // console.log(e)
        this.select = e
        if(e==4){
            let res = await requests.post('/web/get_liucheng')
            // console.log(res)
            this.rlues = res.data.content
        }else if(e==5){
            let res = await requests.post('/web/get_wanttobuy_List')
            // console.log(res)
            this.currentPage = res.data.current_page
            this.total = res.data.total
            this.perPage = res.data.per_page
            this.buyGoods = res.data.data
        }
      },
      // 切换页码
      async  pageChange(e){
        let res = await requests.post('/web/get_wanttobuy_List?page='+e)
        // console.log(res)
            this.buyGoods = res.data.data
      },
      // 免责声明
      async dutyInfo(){
        let res = await requests.post('/web/get_mianze')
        console.log(res)
        this.dutymsg = res.data.content
        this.dutyshow = true
      },
      // 表单提交
      submit() {
        // 联系我们接口：https://www.zgydzqjy.com/api/web/save_contact 接收数据：name：姓名  tpl：电话  content：留言内容
        axios.post('/api/web/save_contact',{
            data:{
              name:this.username,
              tpl:this.phone,
              content:this.content
            }
          }).then(res=>{
            console.log(res);
            this.msg = res.data.msg,
            this.show = true
          }).catch(err=>{
            console.log(err)
          })
      }
    }
}
</script>

<style>
      .contaier{
        width: 100%;
        min-width: 1300px;
        height: 1700px;
        background: linear-gradient(#FF0000, #FFF); 
      }
      .box{
        width: 85%;
        height: 1500px;
        /* border: 1px solid #ccc; */
        margin: 0 auto;
      }
      .titles{
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
      }
      .titles .aa{
        font-size: 38px;
        font-weight: bolder;
        color: #FFF;
        width: 700px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .titles .bb{
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 17px;
        font-weight: bolder;
      }
      .titles img{
        width: 100px;
        height: 100px;
      }
      .nav{
        display: flex;
        align-items: center;
        padding: 0 30px;
        width:95%;
        height: 50px;
        /* background-color: #ccc; */
        justify-content: space-between;
      }
      .yes{
        font-size: 30px;
        font-weight: bold;
        /* width: 30%; */
        height: 100%;
        line-height: 50px;
        text-align: center;
        color: #FFF;
        /* background-color: #fff; */
      }
      .no{
        font-size: 30px;
        font-weight: bold;
        /* width: 30%; */
        height: 100%;
        line-height: 50px;
        text-align: center;
      }
      .content{
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        height: 1200px;
        margin-top: 20px;
      }
      .links{
        padding: 30px 30px;
        font-size: 30px;
        font-weight: bolder;
        color: #FF0000;
      }
      .linkbox{
        margin-left: 29px;
        display: flex;
        align-items: center;
        width: 95%;
        height: 200px;
        border: 1px solid #ccc;
        border-top: 2px solid  #FF0000;
        justify-content: space-around;
      }
      .linkbox div{
        text-align: center;
        font-size: 30px;
        font-weight: bold;
      }
      .linkbox img{
        border-radius: 50%;
        width: 100px;
        height: 100px;
      }
      .love{
         width: 60px;
         text-align: center;
         height: 20px;
         line-height: 20px;
         border-radius: 10px;
         background-color: #FF1414;
         color: #FFF;
         font-weight: bolder;
         font-size: 12px;
         position: absolute;
         margin-top: 5px;
         margin-left: 5px;
      }
      .proBox{
        width: 95%;
        height: 800px;
        /* border: 1px solid #CCC; */
        margin-left: 29px;
        margin-top: 50px;
      }
      .project{
        margin-top: 80px;
        /* width: 100%; */
        height: 700px;
        display: flex;
        justify-content: space-between;
      }
      .project .call{
        width: 50%;
        border: 1px solid #ccc;
        border-top: 2px solid  #FF0000;
        /* background-color: #ccc; */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

      }
      .project .call  .left{
        padding: 20px 13px;
        width: 45%;
        height: 300px;
        font-size: 16px;
        /* text-align: center; */
        white-space:nowrap; 
        overflow:hidden;  
        text-overflow:ellipsis;
      }
      .project .call  .left div{
        margin-left: 50px;
      }
      .project .call  .left img{
        width: 190px;
        height: 190px;
      }
      .middle{
        width: 1px;
        height: 250px;
        background-color: #FF0000;
        position: absolute;
        margin-top: 30px;
        margin-right: 40px;
      }
      .scroll{
        width: 100%;
        font-size: 30px;
        font-weight: bolder;
        color: #FF0000;
        /* background-color: #FF1414; */
        margin-top: -45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
      }
      .project .notice{
        width: 40%;
        height: 697px;
        /* text-align: center; */
        font-size: 30px;
        font-weight: bolder;
        color: #FF0000;
        border: 1px solid #ccc;
        border-top: 2px solid  #FF0000;
      }
      .project .notice .aa{
        margin-top: 10px;
      }
      .project .notice .aa div .van-notice-bar{
        margin-top: 30px;
      }
      .comm{
        margin-top: 30px;
        text-align: center;
        font-size: 40px;
        font-weight: bolder;
        color: #FF1414;
      }
      .area{
        width: 400px;
        /* background-color: #FF0000; */
        margin: 0 auto;
        margin-top: 30px;
      }
      .area div{
        margin-top: 20px;
      }
      .area input{
          width: 343px;
          height: 30px;
          border-radius: 10px;
      }
      .bigInp input{
          width: 343px;
          height: 100px;
          border-radius: 10px;
      }
      .btn{
          background-color: #FF0000;
          color: #FFF;
          font-size: 40px;
          font-weight: bold;
          width: 150px;
          height: 60px;
          border-radius: 20px;
          line-height: 60px;
          text-align: center;
          margin: 50px 0 0 60%;
      }
      .message{
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-weight: bolder;
        color: #FF1414;
      }
      .address{
        margin: 0 auto;
        width: 85%;
        /* height: 100px; */
        /* background-color: #fff;
        border-radius: 10px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 30px;
        font-weight: bold;
        color: #FF1414;
      }
      .address div{
        padding: 20px 30px;
      }
      .introduce{
        width: 85%;
        font-size: 22px;
        font-weight: bolder;
        margin: 0 auto;
      }
      .introduce div{
        /* margin-top: 30px; */
        padding: 0 30px;
      }
      .popup{
        height: 150px;
        line-height: 150px;
        text-align: center;
      }
      .dutypopup{
        /* width: 400px; */
        padding: 0 30px;
        height: 700px;
      }
      .duty{
        margin: 0 auto;
        width: 85%;
        height: 100px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 30px;
        font-weight: bold;
        color: #FF1414;
      }
      .duty img{
        width: 50px;
        height: 50px;
      }
      .duty div{
        padding: 0 30px;
      }
      .buyBox{
        padding: 10px 0;
        /* background-color:#ccc; */
      }
      .buy{
        width: 60%;
        margin: 0 auto;
        background-color:#FF1414;
        color: #FFF;
        border-radius: 10px;
        padding: 0 30px;
        /* margin-top: 20px; */
        font-size: 14px;
        /* padding-bottom: 10px; */
      }
      .buy div{
        margin-top: 3px;
      }
</style>