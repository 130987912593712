import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import { Pagination } from 'vant';
import { NoticeBar } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import VueWechatTitle from 'vue-wechat-title'
import { Dialog } from 'vant';
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(VueWechatTitle)
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(NoticeBar);
import 'vant/lib/index.css';
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // 注册路由
  router
}).$mount('#app')
