import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
// 引入路由
import Link from '../pages/link/link.vue';
import Index from '@/pages/index';
import goods from "../pages/goods/goods.vue"
import more from "../pages/more/more.vue"
export default new VueRouter({
    routes:[
        {
            path:"/index",
            component:Index,
            meta: {
                title: ' 易达法务-首页' 
            }
        },
        {
            path:"/link",
            component:Link,
            meta: {
                title: ' 易达法务-商品详情' 
            }
        },
        {
            path:"/goods",
            component:goods,
            meta: {
                title: ' 易达法务-新闻详情' 
            }
        },
        {
            path:"/more",
            component:more,
            meta: {
                title: ' 易达法务-更多' 
            }
        },
        {
            path:"/",
            redirect:'/index',
            
        }
        
    ]
})